
























































import mixins from 'vue-typed-mixins'
import {
  lessonStore,
  snackbarStore,
} from '@/store'
import LessonCreateEditFields from '@/components/Lesson/CreateEditFields.vue'
import {
  newLesson,
} from '@/util/lesson'
import {
  form,
} from '@/mixins'

export default mixins(form)
  .extend({
    components: {
      LessonCreateEditFields,
    },
    data: () => ({
      loadingCreate: false,
      newLesson: newLesson(),
    }),
    methods: {
      async create () {
        if (!this.validateForm()) return
        try {
          this.loadingCreate = true
          await lessonStore.create(this.newLesson)
          this.$router.go(-1)
          snackbarStore.addSiteMessage({
            type: `success`,
            snackMessage: `Lesson successfully created`,
          })
        } catch (err) {
          snackbarStore.addSiteError({
            err,
            snackMessage: `Error updating lesson`,
          })
        }
        this.loadingCreate = false
      },
      async reset () {
        this.newLesson = newLesson()
      },
    },
  })
